import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL,
  withCredentials: true,
})

Vue.prototype.$http = axiosIns

export default axiosIns
