import axios from '@/libs/axios'

export default {
    namespaced: true,

    state: {
        payments: []
    },

    getters: {
        payments(state) {
            return state.payments
        }
    },

    mutations: {
        SET_PAYMENTS(state, value) {
            state.payments = value
        }
    },

    actions: {
        loadPayments({commit}, data) {
            return axios.get(`api/user-payments/${data.id}`).then(response => {
                commit('SET_PAYMENTS', response.data.data)
            })
        }
    },
}
