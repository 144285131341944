import { initialAbility } from '@/libs/acl/config'

/**
 * Update ability rules for user
 * @param {Object} user
 * @param {Object} ability
 */
export default (user, ability) => {
  if (user && ability) {
    initialAbility.push(
      {
        action: 'manage',
        subject: user.role === 'Admin' ? 'all' : user.role,
      },
    )

    ability.update(initialAbility)
  }
}
