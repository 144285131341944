import axios from '@/libs/axios'

export default {
    namespaced: true,

    state: {
        trips: []
    },

    getters: {
        trips(state) {
            return state.trips
        }
    },

    mutations: {
        SET_TRIPS(state, value) {
            state.trips = value
        },
        UPDATE_TRIP(state, data) {
            const index = state.trips.findIndex(trip => trip.id == data.id);
            Object.assign(state.trips[index], data);//Object.fromEntries(data)
        },
        ADD_TRIP(state, data) {
            state.trips.push(data); //= {...state.trips, ...data};
        },
        DELETE_TRIP(state, id) {
            const index = state.trips.findIndex(trip => trip.id === id)
            state.trips.splice(index, 1)
        },
    },

    actions: {
        loadTrips({ commit }) {
            return axios.get('api/trips').then(response => {
                commit('SET_TRIPS', response.data.data)
            })
        },
        updateTrip({ commit }, data ) {
            return axios.put(`api/trips/${data.id}`, data).then(() => {
                commit('UPDATE_TRIP', data )
            })
        },
        addTrip({ commit }, data ) {
            return axios.post('api/trips', data).then((response) => {
                commit('ADD_TRIP', response.data.data )
            })
        },
        deleteTrip({ commit }, id) {
            return axios.delete(`api/trips/${id}`).then(() => {
                commit('DELETE_TRIP', id)
            })
        },
    },
}
