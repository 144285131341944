import axios from '@/libs/axios'

export default {
    namespaced: true,

    state: {
        announcements: [],
        announcementsForUser: []
    },

    getters: {
        announcements(state) {
            return state.announcements
        },
        announcementsForUser(state) {
            return state.announcementsForUser
        }
    },

    mutations: {
        SET_ANNOUNCEMENTS_FOR_USER(state, value) {
            state.announcementsForUser = value
        },
        SET_ANNOUNCEMENTS(state, value) {
            state.announcements = value
        },
        UPDATE_ANNOUNCEMENT(state, data) {
            const index = state.announcements.findIndex(trip => trip.id == data.id);
            Object.assign(state.announcements[index], data);
        },
        ADD_ANNOUNCEMENT(state, data) {
            state.announcements.push(data);
        },
        DELETE_ANNOUNCEMENT(state, id) {
            const index = state.announcements.findIndex(trip => trip.id === id);
            state.announcements.splice(index, 1)
        },
    },

    actions: {
        loadAnnouncementsForUser({ commit }) {
            return axios.post('api/announcements-for-user').then(response => {
                commit('SET_ANNOUNCEMENTS_FOR_USER', response.data.data)
            })
        },
        loadAnnouncements({ commit }) {
            return axios.get('api/announcements').then(response => {
                commit('SET_ANNOUNCEMENTS', response.data.data)
            })
        },
        updateAnnouncement({ commit }, data ) {
            return axios.put(`api/announcements/${data.id}`, data).then(() => {
                commit('UPDATE_ANNOUNCEMENT', data )
            })
        },
        addAnnouncement({ commit }, data ) {
            return axios.post('api/announcements', data).then((response) => {
                commit('ADD_ANNOUNCEMENT', response.data.data )
            })
        },
        deleteAnnouncement({ commit }, id) {
            return axios.delete(`api/announcements/${id}`).then(() => {
                commit('DELETE_ANNOUNCEMENT', id)
            })
        },
    },
}
