import axios from '@/libs/axios'
import updateAbility from '@/libs/acl/utils'

export default {
    namespaced: true,

    state: {
        role: null,
        roles: [],
    },

    getters: {

        role(state) {
            return state.role
        },
        roles(state) {
            return state.roles
        },
    },

    mutations: {

        SET_ROLE(state, value) {
            state.role = value
        },
        SET_ROLES(state, value) {
            state.roles = value
        }
    },

    actions: {

        loadRole({ commit }, ability) {
            return axios.get('api/role').then(response => {
                commit('SET_ROLE', response.data.data)

                updateAbility(response.data.data, ability)
            }).catch(() => {
                commit('SET_ROLE', null)
            })
        },
        loadRoles({ commit }) {
            return axios.get('api/roles').then(response => {
                commit('SET_ROLES', response.data.data)
            })
        },
    },
}
