import axios from '@/libs/axios'

export default {
  namespaced: true,

  state: {
    customers: [],
  },

  getters: {
    customers(state) {
      return state.customers
    },
  },

  mutations: {
    SET_CUSTOMERS(state, value) {
      state.customers = value
    },
  },

  actions: {
    loadCustomers({ commit }) {
      return axios.get('api/customers').then(response => {
        commit('SET_CUSTOMERS', response.data.data)
      })
    },
    async recovery({ commit }, data) {
      await axios.post('api/send-password-recovery', data);
    },
  },
}
