import Vue from 'vue'
import Toast from 'vue-toastification'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// Toast Notification Component Styles
import '@core/scss/vue/libs/toastification.scss'

/**
 * NOTE: If you are using other transition them make sure to import it in `src/@core/scss/vue/libs/notification.scss` from it's source
 */
Vue.use(Toast, {
  hideProgressBar: true,
  closeOnClick: false,
  closeButton: false,
  icon: false,
  timeout: 3000,
  transition: 'Vue-Toastification__fade',
})

export function toastSuccess(message) {
  Vue.$toast({
    component: ToastificationContent,
    position: 'top-right',
    props: {
      title: 'Success',
      icon: 'EditIcon',
      variant: 'success',
      text: message,
    },
  })
}

export function toastFailure(message) {
  Vue.$toast({
    component: ToastificationContent,
    position: 'top-right',
    props: {
      title: 'Error',
      icon: 'AlertTriangleIcon',
      variant: 'danger',
      text: message,
    },
  })
}
