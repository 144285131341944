import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { canNavigate } from '@/libs/acl/routeProtection'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Dashboard.vue'),
      meta: {
        action: 'read',
        resource: 'Customer',
      },
    },
    {
      path: '/charter-survey/:bookingId',
      name: 'charter-survey',
      component: () => import('@/views/CharterSurvey.vue'),
      meta: {
        action: 'read',
        resource: 'Page',
        isAuthenticated: true,
      },
    },
    {
      path: '/book-your-trip',
      name: 'book-trip',
      component: () => import('@/views/BookTrip.vue'),
      meta: {
        action: 'read',
        resource: 'Page',
      },
    },
    {
      path: '/book-harbor-cruise',
      name: 'book-harbor-cruise',
      component: () => import('@/views/BookHarborCruise.vue'),
      meta: {
        action: 'read',
        resource: 'Page',
      },
    },
    {
      path: '/check-my-charter',
      name: 'check-my-charter',
      component: () => import('@/views/CheckMyCharter.vue'),
      meta: {
        action: 'read',
        resource: 'Page',
      },
    },
    {
      path: '/trip-preparations',
      name: 'trip-preparations',
      component: () => import('@/views/TripPreparations.vue'),
      meta: {
        action: 'read',
        resource: 'Page',
      },
    },
    {
      path: '/faq',
      name: 'faq',
      component: () => import('@/views/Faq.vue'),
      meta: {
        action: 'read',
        resource: 'Page',
      },
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/views/Profile.vue'),
      meta: {
        action: 'read',
        resource: 'Page',
        isAuthenticated: true,
      },
    },
    {
      path: '/logout',
      name: 'logout',
      meta: {
        action: 'read',
        resource: 'Page',
        isAuthenticated: true,
      },
      beforeEnter (to, from, next) {
        store.dispatch('auth/logout').then(() => {
          next({ name: 'login' })
        })
        next(false)
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'Page',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/Register.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'Page',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/forgot-password',
      name: 'recovery',
      component: () => import('@/views/Recovery.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'Page',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/reset-password/:token',
      name: 'reset',
      component: () => import('@/views/Reset.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'Page',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/admin/bookings',
      name: 'bookings',
      component: () => import('@/views/Bookings.vue'),
      meta: {
        action: 'manage',
        resource: 'Captain',
      },
    },
    {
      path: '/admin/inshore-trips',
      name: 'inshore',
      component: () => import('@/views/InshoreTrips.vue'),
      meta: {
        action: 'manage',
        resource: 'Admin',
      },
    },
    {
      path: '/admin/cruise-trips',
      name: 'cruise',
      component: () => import('@/views/CruiseTrips.vue'),
      meta: {
        action: 'manage',
        resource: 'Admin',
      },
    },
    {
      path: '/admin/trips',
      name: 'trips',
      component: () => import('@/views/Trips.vue'),
      meta: {
        action: 'manage',
        resource: 'Admin',
      },
    },
    {
      path: '/admin/boats',
      name: 'boats',
      component: () => import('@/views/Boats.vue'),
      meta: {
        action: 'manage',
        resource: 'Captain',
      },
    },
    {
      path: '/admin/announcements',
      name: 'announcements',
      component: () => import('@/views/Announcements.vue'),
      meta: {
        action: 'manage',
        resource: 'Admin',
      },
    },
    {
      path: '/admin/customers',
      name: 'customers',
      component: () => import('@/views/Customers.vue'),
      meta: {
        action: 'manage',
        resource: 'Captain',
      },
    },
    {
      path: '/admin/users',
      name: 'users',
      component: () => import('@/views/Users.vue'),
      meta: {
        action: 'manage',
        resource: 'Admin',
      },
    },
    {
      path: '/admin/daily-log',
      name: 'daily-log',
      component: () => import('@/views/DailyLog.vue'),
      meta: {
        action: 'manage',
        resource: 'Captain',
      },
    },
    {
      path: '/admin/daily-catch',
      name: 'daily-catch',
      component: () => import('@/views/DailyCatch.vue'),
      meta: {
        action: 'manage',
        resource: 'Captain',
      },
    },
    {
      path: '/admin/calendar',
      name: 'calendar',
      component: () => import('@/views/Calendar.vue'),
      meta: {
        action: 'manage',
        resource: 'Captain',
      },
    },

    {
      path: '/admin/new-customer',
      name: 'new-customer',
      component: () => import('@/views/CreateCustomer.vue'),
      meta: {
        action: 'manage',
        resource: 'Admin',
      },
    },
    {
      path: '/admin/new-booking',
      name: 'new-booking',
      component: () => import('@/views/CreateBooking.vue'),
      meta: {
        action: 'manage',
        resource: 'Admin',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'Page',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = store.getters['auth/authenticated']

  if (to.meta.isAuthenticated && !isLoggedIn) next({ name: 'login' })

  setTimeout(() => {
    if (
      to.name === 'home'
      && store.getters['auth/user']
      && (
        store.getters['auth/user'].role === 'Captain'
        || store.getters['auth/user'].role === 'Admin'
      )
    ) return next({ name: 'calendar' })

    if (!canNavigate(to)) {
      if (!isLoggedIn) return next({ name: 'login' })

      return next({ name: 'error-404' })
    }

    if (to.meta.redirectIfLoggedIn && isLoggedIn) next({ name: 'home' })

    return next()
  }, 0)
})

export default router
