import axios from '@/libs/axios'
import updateAbility from '@/libs/acl/utils'

export default {
  namespaced: true,

  state: {
    authenticated: false,
    user: null,
    users: [],
  },

  getters: {
    authenticated(state) {
      return state.authenticated
    },

    user(state) {
      return state.user
    },

    users(state) {
      return state.users
    },
  },

  mutations: {
    SET_AUTHENTICATED(state, value) {
      state.authenticated = value
    },

    SET_USER(state, value) {
      state.user = value
    },

    SET_USERS(state, value) {
      state.users = value
    },

    UPDATE_USER(state, userData) {
      if (userData.card) userData.card = JSON.parse(userData.card)
      Object.assign(state.user, userData)
    },

    UPDATE_USER_EDITED(state, userData) {
      const index = state.users.findIndex(user => user.id === userData.id);
      if( index >= 0 ){
        Object.assign(state.users[index], userData);
      }
    },

    ADD_USER(state, userData) {
      userData.role = userData.role_name;
      state.users.push(userData);
    },
    SET_ACTIVE_CARD(state, value) {
      state.user.card = value
    }
  },

  actions: {
    async login({ dispatch }, { credentials, ability }) {
      await axios.get('sanctum/csrf-cookie')
      await axios.post('login', credentials)

      return dispatch('loadUser', ability)
    },

    async logout({ dispatch }) {
      await axios.get('sanctum/csrf-cookie')
      await axios.post('logout')

      return dispatch('loadUser')
    },

    async register({ dispatch }, { credentials, ability }) {
      await axios.get('sanctum/csrf-cookie')
      await axios.post('register', credentials)

      return dispatch('loadUser', ability)
    },

    async recovery(_, credentials) {
      await axios.get('sanctum/csrf-cookie')
      await axios.post('forgot-password', credentials)
    },

    reset(_, credentials) {
      return axios.post('reset-password', credentials)
    },

    loadUser({ commit }, ability) {
      return axios.get('api/user').then(response => {
        commit('SET_AUTHENTICATED', true)
        commit('SET_USER', response.data.data)

        updateAbility(response.data.data, ability)
      }).catch(() => {
        commit('SET_AUTHENTICATED', false)
        commit('SET_USER', null)
      })
    },

    loadUsers({ commit }) {
      return axios.get('api/users').then(response => {
        commit('SET_USERS', response.data.data)
      })
    },

    deleteUser(_, credentials) {
      //return axios.post('api/user', credentials)
    },
    createUser(_, credentials) {
      return axios.post('api/user', credentials)
    },
    createAdminUser({ commit }, credentials) {
      return axios.post('api/user', credentials).then((response)=>{
        commit('ADD_USER', response.data);
      })
    },
    updateUser({ commit }, data) {

      return axios.post(`api/user/${data.get('id')}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then((response) => {
        commit('UPDATE_USER', Object.fromEntries(data));
        commit('UPDATE_USER_EDITED', response.data.data);
      })
    },
  },
}
