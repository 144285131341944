import axios from '@/libs/axios'

export default {
  namespaced: true,

  state: {
    bookings: [],
    bookingsWithPayments: [],
    myBookings: [],
    myBookingsWithPayments: [],
    charters: [],
  },

  getters: {
    bookings(state) {
      return state.bookings
    },
    bookingsWithPayments(state) {
      return state.bookingsWithPayments
    },
    myBookings(state) {
      return state.myBookings
    },
    myBookingsWithPayments(state) {
      return state.myBookingsWithPayments
    },
    charters(state) {
      return state.charters;
    },
  },

  mutations: {
    SET_BOOKINGS(state, value) {
      state.bookings = value
    },
    SET_BOOKINGS_WITH_PAYMENTS(state, value) {
      state.bookingsWithPayments = value;
      state.bookings = value;
    },
    SET_MY_BOOKINGS(state, value) {
      state.myBookings = value
    },
    SET_MY_BOOKINGS_WITH_PAYMENTS(state, value) {
      state.myBookingsWithPayments = value;
      state.myBookings = value;
    },
    SET_CHARTERS(state, value) {
      state.charters = value
    },
    UPDATE_BOOKINGS(state, changedBooking) {
      const index = state.bookings.findIndex(booking => booking.id === changedBooking.id);
      Object.assign(state.bookings[index], changedBooking)
      if (changedBooking.cancelled) {
        state.bookings[index].status = 1
      }
    },
    UPDATE_MY_BOOKINGS(state, changedBooking) {
      const index = state.myBookings.findIndex(booking => booking.id === changedBooking.id)
      Object.assign(state.myBookings[index], changedBooking)
      if (changedBooking.cancelled) {
        state.myBookings[index].status = 1
      }
    },
    DELETE_BOOKINGS(state, changedBookingId) {
      const index = state.bookings.findIndex(booking => booking.id === changedBookingId)
      state.bookings.splice(index, 1)
    },
  },

  actions: {
    loadBookings({ commit }) {
      return axios.get('api/bookings').then(response => {
        commit('SET_BOOKINGS', response.data.data)
      })
    },
    loadNotCruiseBookingsWithPayments({ commit }) {
      return axios.get('api/notCruiseBookingsWithPayments').then(response => {
        commit('SET_BOOKINGS_WITH_PAYMENTS', response.data.data)
      })
    },
    loadBookingsWithPayments({ commit }) {
      return axios.get('api/bookingsWithPayments').then(response => {
        commit('SET_BOOKINGS_WITH_PAYMENTS', response.data.data)
      })
    },
    loadCruiseBookingsWithPayments({ commit }) {
      return axios.get('api/cruiseBookingsWithPayments').then(response => {
        commit('SET_BOOKINGS_WITH_PAYMENTS', response.data.data)
      })
    },
    loadInshoreTripsWithPayments({ commit }) {
      return axios.get('api/inshoreTripsWithPayments').then(response => {
        commit('SET_BOOKINGS_WITH_PAYMENTS', response.data.data)
      })
    },
    loadMyBookings({ commit }) {
      return axios.get('api/my-bookings').then(response => {
        commit('SET_MY_BOOKINGS', response.data.data)
      })
    },
    loadMyBookingsWithPayments({ commit }) {
      return axios.get('api/my-bookings-with-payments').then(response => {
        commit('SET_MY_BOOKINGS_WITH_PAYMENTS', response.data.data)
      })
    },
    loadCharters({ commit }) {
      return axios.get('api/charters').then(response => {
        commit('SET_CHARTERS', response.data.data)
      })
    },
    createBooking(_, data) {
      return axios.post('api/bookings', data)
    },
    payAndCreateBooking(_, data) {
      return axios.post('api/booking/create', data)
    },
    createBookingManually(_, data) {
      return axios.post('api/create-booking', data)
    },
    updateBooking({ commit }, data) {
      return axios.put(`api/bookings/${data.id}`, data).then(response => {
        commit('UPDATE_BOOKINGS', response.data.data);
        return response.data;
      })
    },
    refundBookingPayment({ commit }, data) {
      return axios.post('api/refund-payment', data).then(response => {
        commit('UPDATE_BOOKINGS', response.data.data)
      })
    },
    updateMyBooking({ commit }, data) {
      return axios.put(`api/bookings/${data.id}`, data).then(() => {
        commit('UPDATE_MY_BOOKINGS', data)
      })
    },
    makeBookingPayment({ commit }, data) {
      return axios.post('api/make-payment', data).then((response) => {

        console.log('response.data.data', response.data.data);
        commit('UPDATE_MY_BOOKINGS', response.data.data);
        return response.data;
      })
    },
    deleteBooking({ commit }, id) {
      return axios.delete(`api/bookings/${id}`).then(() => {
        commit('DELETE_BOOKINGS', id)
      })
    },
    resendEmail({ commit }, data) {
      return axios.post(`api/booking-email-resend/${data.id}`, data).then(response => {
        return response.data;
      })
    },
  },
}
