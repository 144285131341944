import axios from '@/libs/axios'

export default {
  namespaced: true,

  state: {
    boats: [],
    boatNames: [],
    boatNamesPictures: [],
    boatNamesTripsPictures: [],
  },

  getters: {
    boats(state) {
      return state.boats
    },
    boatNames(state) {
      return state.boatNames
    },
    boatNamesPictures(state) {
      return state.boatNamesPictures
    },
    boatNamesTripsPictures(state) {
      return state.boatNamesTripsPictures
    },
  },

  mutations: {
    SET_BOATS(state, value) {
      state.boats = value
    },
    SET_BOAT_NAMES(state, value) {
      state.boatNames = value
    },
    SET_BOAT_NAMES_PICTURES(state, value) {
      state.boatNamesPictures = value
    },
    SET_BOAT_NAMES_TRIPS_PICTURES(state, value) {
      state.boatNamesTripsPictures = value
    },
    UPDATE_BOATS(state, { formData, temporaryPicture }) {
      if (temporaryPicture) formData.set('picture', temporaryPicture)
      /* eslint-disable-next-line */
      const index = state.boats.findIndex(boat => boat.id == formData.get('id'))
      Object.assign(state.boats[index], Object.fromEntries(formData))
    },
  },

  actions: {
    loadBoats({ commit }) {
      return axios.get('api/boats').then(response => {
        commit('SET_BOATS', response.data.data)
      })
    },
    loadBoatNames({ commit }) {
      return axios.get('api/boat-names').then(response => {
        commit('SET_BOAT_NAMES', response.data)
      })
    },
    loadBoatNamesPictures({ commit }) {
      return axios.get('api/boat-names-pictures').then(response => {
        commit('SET_BOAT_NAMES_PICTURES', response.data.data)
      })
    },
    loadBoatNamesTripsPictures({ commit }) {
      return axios.get('api/boat-names-trips-pictures').then(response => {
        commit('SET_BOAT_NAMES_TRIPS_PICTURES', response.data.data)
      })
    },
    updateBoat({ commit }, { formData, temporaryPicture }) {
      return axios.post(`api/boats/${formData.get('id')}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(() => {
        commit('UPDATE_BOATS', { formData, temporaryPicture })
      })
    },
  },
}
