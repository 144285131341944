import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import auth from './auth'
import bookings from './bookings'
import boats from './boats'
import customers from './customers'
import cards from './cards'
import trips from './trips'
import announcements from './announcements'
import roles from './roles'
import payments from './payments'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth,
    bookings,
    boats,
    customers,
    cards,
    trips,
    announcements,
    roles,
    payments,
  },
  strict: process.env.DEV,
})
