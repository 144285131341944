import axios from '@/libs/axios'

export default {
  namespaced: true,

  state: {
    cards: [],
  },

  getters: {
    cards(state) {
      return state.cards
    },
  },

  mutations: {
    SET_CARDS(state, value) {
      state.cards = value
    },
    ADD_CARDS(state, value) {
      state.cards.push(value)
    },
    DELETE_CARDS(state, cardId) {
      const index = state.cards.findIndex(card => card.id === cardId)
      state.cards.splice(index, 1)
    },
    SET_DEFAULT_CARD(state, cardId) {
      if (state.cards.length) {
        const index = state.cards.findIndex(card => {
          return card.id === cardId
        })

        state.cards[index].active = true
      }
    },
  },

  actions: {
    loadCards({ commit }) {
      return axios.get('api/cards').then(response => {
        commit('SET_CARDS', response.data.data)
      })
    },
    createCard({ commit, state }, data) {
      return axios.post('api/cards', data).then(response => {
        commit('ADD_CARDS', response.data.data)
        if (state.cards.length == 1) {
          commit('auth/SET_ACTIVE_CARD', response.data.data, { root: true })
          commit('SET_DEFAULT_CARD', response.data.data.id)
        }
      })
    },
    async deleteCard({ commit, dispatch }, id) {
      await axios.delete(`api/cards/${id}`)
      return dispatch('getDefaultCard', id)
    },
    getDefaultCard({ commit, state }, deletedCardId) {
      return axios.get('api/cards/default').then(response => {
        commit('DELETE_CARDS', deletedCardId)
        commit('auth/SET_ACTIVE_CARD', response.data.data, { root: true })
        if (state.cards.length) {
          commit('SET_DEFAULT_CARD', response.data.data.id)
        }
      })
    }
  },
}
